import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ForgotPassword from '../components/ForgotPassword';
import { I18nextContext } from "gatsby-plugin-react-i18next";

const ForgotPasswordPage: React.FC = () => {
  const { language } = React.useContext(I18nextContext);

  return (
    <Layout page='forgot-password' locale={language}>
        <SEO title="Forgot Password" />
        <ForgotPassword locale={language}/>
    </Layout>
  );
};

export default ForgotPasswordPage;
