import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrapper = styled.div`
  ${tw`bg-white w-full xl:w-c1 h-auto mx-auto py-8 px-4 sm:px-8 flex flex-col `};
  a{
    ${tw`text-blue-500`};
  }
  @media (max-width: 1640px) {
    ${tw`w-4/12`};
  }
  @media (max-width: 1315px) {
    ${tw`w-5/12`};
  }
  @media (max-width: 1064px) {
    ${tw`w-6/12`};
  }
  @media (max-width: 768px) {
    ${tw`w-8/12`};
  }
  @media (max-width: 624px) {
    ${tw`w-full`};
  }
  .title{
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #4D4545;
    margin: 4px 0px;
  }
  .subTitle{
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #383C3F;
    margin: 4px 0px;
  }
`;

export const Text = styled.p`
  letter-spacing: 0.04em;
  ${tw`text-sm text-brown-darker m-0 font-medium mb-1`};
  ${({ bold }) => (bold ? tw`font-bold text-base text-brown-semidark` : tw``)};
  ${({ center }) => (center ? tw`my-4 mx-auto text-center` : tw``)};
  ${({ left }) => (left ? tw`flex-auto text-xs text-left` : tw``)};
  ${({ right }) => (right ? tw`flex-auto text-xs text-right` : tw``)};
  ${({ small }) => (small ? tw`text-xs  font-semibold` : tw``)};
  ${({ centermb }) => (centermb ? tw`mb-2 mx-auto text-center` : tw``)};
`;

export const Button = styled.button`
  letter-spacing: 0.05em;
  ${tw`flex mx-auto justify-center bg-black text-white text-base mt-6 font-medium tracking-wider gap-2 py-3 px-8`};
`;

export const ButtonLink = styled.button`
  ${tw`text-xs text-blue-600`};
`;

export const Mob = styled.div`
  ${tw`block sm:hidden`};
`;

export const Desk = styled.div`
  ${tw`hidden sm:block`};
`;

export const MobileTtitle = styled.p`
  letter-spacing: 0.04em;
  ${tw`text-base text-center text-brown-semidark font-bold`};
`;

export const MobileText = styled.p`
  ${tw`mb-6 text-sm text-center text-brown-darker`};
`;