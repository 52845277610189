import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import Container from 'components/ui/Container';
import * as Styled from './styles';
import { requestResetPassword } from '../../state/user/user.service';
import Error from './../../assets/images/error.png';
import { graphql, useStaticQuery } from 'gatsby';
import Danger from '../ui/Alert/Danger';
import { useI18next } from "gatsby-plugin-react-i18next";
import GoBackLink from "../ui/GoBackLink";

const ForgotPassword = ({ locale }) => {
  const { navigate } = useI18next();
  const gql = useStaticQuery(graphql`
    query {
      strapiForgotPasswordPage_en: strapiForgotPasswordPage(locale: { eq: "en" }) {
        forgot_password_heading
        forgot_password_text
        email_field_label
        send_email_button_text
        reset_password_sent_success_text
        reset_instructions_text
      }
      strapiForgotPasswordPage_zh: strapiForgotPasswordPage(locale: { eq: "zh" }) {
        forgot_password_heading
        forgot_password_text
        email_field_label
        send_email_button_text
        reset_password_sent_success_text
        reset_instructions_text
      }
      strapiErrorMessages_en: strapiErrorMessages(locale: { eq: "en" }) {
        email_address_required
        invalid_email_address
        unknown_error
        server_error_500
        server_error_502
        request_error_422
      }
      strapiErrorMessages_zh: strapiErrorMessages(locale: { eq: "zh" }) {
        email_address_required
        invalid_email_address
        unknown_error
        server_error_500
        server_error_502
        request_error_422
      }
    }
  `);

  const translations = gql[`strapiForgotPasswordPage_${locale}`];
  const errTranslations = gql[`strapiErrorMessages_${locale}`];
  const [errors, setErrors] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);

  function checkBtn() {
    const btn = document.getElementById('submitBtn');

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(document.getElementById('email').value)) {
      btn.disabled = true;
      btn.classList.add('disabledBtn');
      btn.classList.remove('enabledBtn');
    } else {
      btn.disabled = false;
      btn.classList.remove('disabledBtn');
      btn.classList.add('enabledBtn');
    }
  }

  return (
    <Container section>
      <Styled.Wrapper>
        <Formik
          initialValues={{ validateOnMount: true, email: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = errTranslations.email_address_required;
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = errTranslations.invalid_email_address;
            }

            return errors;
          }}
          onSubmit={async (values) => {
            const resetPasswordResponse = await requestResetPassword(values.email)
              .toPromise()
              .catch((ajax) => {
                switch (ajax.status) {
                  case 500:
                    setErrors([errTranslations.server_error_500]);
                    break;
                  case 502:
                    setErrors([errTranslations.server_error_502]);
                    break;
                  case 422:
                    setErrors([errTranslations.request_error_422]);
                    break;
                  default:
                    setErrors([errTranslations.unknown_error]);
                    break;
                }
              });

            if (resetPasswordResponse.statusCode === 200) {
              setIsSuccess(true);
            }
          }}
        >
          {() => (
            <Form>
              {!isSuccess && (
                <>
                  <div className="title">{translations.forgot_password_heading}</div>
                  <div className="subTitle">{translations.forgot_password_text}</div>
                  <br />
                  <Styled.Text small>{translations.email_field_label}</Styled.Text>
                  <Field type="email" name="email">
                    {({ field, meta: { touched, error } }) => (
                      <div className="relative">
                        <input
                          type="email"
                          className={
                            touched && error
                              ? 'errorMsg bg-redu-light border border-redu-light text-redu-mdlight'
                              : ''
                          }
                          {...field}
                          id="email"
                          onKeyUp={checkBtn}
                        />
                        <img
                          src={Error}
                          alt="error"
                          className={
                            touched && error
                              ? 'absolute right-2 top-3 block'
                              : 'absolute right-2 top-3 hidden'
                          }
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="my-1 pb-2 text-xs text-redu-mdlight"
                  />
                  {errors.map((err) => (
                    <Danger message={err} />
                  ))}
                  <button type="submit" id="submitBtn" className="disabledBtn mb-4" disabled>
                    {translations.send_email_button_text}
                  </button>

                  <GoBackLink title={locale === 'en' ? 'Go Back' : '后退'} link='/login'/>
                </>
              )}
              {isSuccess && (
                <div className="mt-2 items-center" role="alert">
                  <div className="title">{translations.reset_password_sent_success_text}</div>
                  <div className="subTitle py-4">{translations.reset_instructions_text}</div>
                  <GoBackLink title={locale === 'en' ? 'Go Back' : '后退'} link='/login'/>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Styled.Wrapper>
    </Container>
  );
};

ForgotPassword.propTypes = {
  locale: PropTypes.string,
};

ForgotPassword.defaultProps = {
  locale: 'en',
};

export default ForgotPassword;
